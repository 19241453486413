import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import loadable from "@loadable/component"
//import { Link } from "gatsby"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './assets/styles/_index.scss'

import ServicesImg1 from "../../images/explore_services_img_1.png";
import ServicesImg2 from "../../images/explore_services_img_2.png";
import ServicesImg3 from "../../images/explore_services_img_3.png";
import ServicesImg4 from "../../images/explore_services_img_4.png";

const Slider = loadable(() => import("react-slick"));

const ExploreServices = (props) => {

    // Slider settings
    let settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: false,
                    dots: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: false,
                    dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    speed: 400,
                    arrows: false,
                    dots: true,
                },
            },
        ],
    }
    // Slider settings

    return (
        <section className="explore-services-wrapper">
            <Container>
                <Row>
                    <Col className="text-center">
                        <h2>Explore Our Services</h2>
                        <div className="explore-services-sub-title">Lorem ipsum dolor sit amet lipsum dolor consectetur adipiscing <Link to="">Get in touch</Link>.</div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Slider className="explore-services-slider" {...settings}>
                            <div className="explore-services-slide">
                                <div className="explore-services-img-zoom">
                                    <Link to="">
                                        <img src={ServicesImg1} className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="explore-services-text-wrapper">
                                    <div className="explore-services-title">Value my home</div>
                                    <div className="explore-services-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem industry's standard dummy text.</div>
                                    <div className="explore-services-link">
                                        <Link to="">Find Out More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="explore-services-slide">
                                <div className="explore-services-img-zoom">
                                    <Link to="">
                                        <img src={ServicesImg2} className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="explore-services-text-wrapper">
                                    <div className="explore-services-title">Property Management</div>
                                    <div className="explore-services-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem industry's standard dummy text.</div>
                                    <div className="explore-services-link">
                                        <Link to="">Find Out More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="explore-services-slide">
                                <div className="explore-services-img-zoom">
                                    <Link to="">
                                        <img src={ServicesImg3} className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="explore-services-text-wrapper">
                                    <div className="explore-services-title">Letting or Renting</div>
                                    <div className="explore-services-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem industry's standard dummy text.</div>
                                    <div className="explore-services-link">
                                        <Link to="">Find Out More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="explore-services-slide">
                                <div className="explore-services-img-zoom">
                                    <Link to="">
                                        <img src={ServicesImg4} className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="explore-services-text-wrapper">
                                    <div className="explore-services-title">Off Plan Properties</div>
                                    <div className="explore-services-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem industry's standard dummy text.</div>
                                    <div className="explore-services-link">
                                        <Link to="">Find Out More</Link>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ExploreServices