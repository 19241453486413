import React, { useState } from "react"
//import { Link } from "gatsby"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { Offcanvas } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import useHasScrolled from "../../hooks/useHasScrolled"
import BurgerMenu from "../BurgerMenu/BurgerMenu"
import FavLogoImg from "../../images/favlogo.svg";
// import LogoWhiteImg from "../../images/logo-white.svg";
import './assets/styles/_index.scss'
import { PageLinks } from "../../common/site/page-static-links"
//const { CTALink } = require("@starberry/gatsby-theme-utils/Modules/CTALinkModule")
import {CTALink} from "../../utils/common/utils"

const HeaderLogoCenter = (props) => {

    // Scroll
    const scrolled = useHasScrolled()
    // Scroll

    //
    const [showBurger, setShowBurger] = useState(false);
    const handleCloseBurger = () => setShowBurger(false);
    const handleShowBurger = () => setShowBurger(true); 
    //

    const data = useStaticQuery(graphql`
    query {
        strapiThemeConfig {
          theme_style
          logo_dark {
            url
          }
          logo_light {
            url
          }
        }
        strapiSiteConfig {
            Top_Navigation {
              title
              link {
                ...MenuFragment
              }
            }
            mobile_device_phone
            whatsapp
            locale
        }
    }
    `);
    const siteconfig = data.strapiSiteConfig;
    const logopath = data.strapiThemeConfig;
    const themestyle = data.strapiThemeConfig?.theme_style;
    const menus = data?.strapiSiteConfig?.Top_Navigation.slice(0,3);
    let whatsapp = siteconfig?.whatsapp && siteconfig?.whatsapp?.replace(/\D/g, "");
    whatsapp = `https://wa.me/${whatsapp}?text=Hi`
    return (
        <>
            <div className={`header-wrap ${scrolled ? "header-scrolled" : ""} ${themestyle === "dubai" ? "header-dubai" : ""}`}>
                <div className="header-container">
                    <div className="header-left">
                        {menus && menus.slice(0, 2).map((item, i) => (
                            <div className="menu-dropdown d-lg-flex d-none">
                            <CTALink {...item} class={`nav-link`} target_window={item.link.target_window} />
                            </div>
                        ))}
                    </div>
                    <Link to="/" className="header-center">
                        {
                            scrolled ?
                            <img src={FavLogoImg} className="logo-img" alt={`${process.env.GATSBY_SITE_NAME} logo`} width="200" height="auto"/>
                            :
                            <img src={logopath.logo_light.url} className="logo-img" alt={`${process.env.GATSBY_SITE_NAME} logo`} width="200" height="auto"/>
                        }
                    </Link>
                    <div className="header-right">
                        <div className="menu-dropdown d-lg-none">
                            <Link to={`/${PageLinks.results_sales}/`} className="nav-link d-flex align-items-center" aria-label="Search Icon"><i className="icon icon-search"></i></Link>
                        </div>
                        {menus && menus.slice(2, 3).map((item, i) => (
                            <div className="menu-dropdown d-lg-flex d-none">
                            <CTALink {...item} class={`nav-link`} target_window={item.link.target_window} />
                            </div>
                        ))}
                        <div className="menu-dropdown">
                            <a href={whatsapp} target="_blank" className="nav-link d-flex align-items-center" aria-label="Whatsapp"><i className="icon icon-header-center-whatsapp"></i> <span className="d-lg-flex d-none">Whatsapp</span></a>
                        </div>
                        <div className="menu-dropdown">
                            <button onClick={() => handleShowBurger()} className="nav-link" aria-label="Burger Menu">
                                <i className="icon icon-header-center-burgermenu"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Offcanvas show={showBurger} onHide={handleCloseBurger} placement="top" className={`burger-menu-wrapper ${themestyle === "dubai" ? "header-dubai" : ""}`}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <BurgerMenu />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default HeaderLogoCenter